import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import downloadBlob from "../lib/download-blob";
import { projectTitleInitialState } from "../reducers/project-title";
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
class SB3Downloader extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, ["downloadProject"]);
    }
    downloadProject(token, clientId) {
        // console.log("token download", token);
        this.props.saveProjectSb3().then((content) => {
            console.log("ARQUIVO BLOB", content);

            const formData = new FormData();
            formData.append("file", content, "scratchProject.png");
            const uploadUrl = "https://exemplo.com/api/upload";

            const myHeaders = new Headers();
            myHeaders.append("clientid", clientId);
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
            };

            fetch(uploadUrl, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            `Erro no upload: ${response.statusText}`
                        );
                    }
                    return response.json(); // Parse da resposta em JSON
                })
                .then((data) => {
                    console.log("Upload bem-sucedido:", data);
                })
                .catch((error) => {
                    console.error("Erro ao realizar o upload:", error.message);
                });

            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            downloadBlob(this.props.projectFilename, content);

            // try {
            //     console.log("ARQUIVO BLOB", content);

            //     const formData = new FormData();
            //     formData.append("file", content, "scratchProject.png");

            //     const uploadUrl = "https://exemplo.com/api/upload";

            //     fetch(uploadUrl, {
            //         method: "POST",
            //         body: formData,
            //     }).then((res) =>
            //         res.json().catch((err) => console && console.error(err))
            //     );

            //     // fetch(
            //     //     `${ApiUrl}Apisx/eventos_public/getMetatag?evtconf_item_hash=${evtconf_item_hash}`
            //     // ).then((res) =>
            //     //     res.json().catch((err) => console && console.error(err))
            //     // );
            // } catch (error) {
            //     console.log("ERRO AO BAIXAR ARQUIVO");
            //     throw error;
            // } finally {
            //     if (this.props.onSaveFinished) {
            //         this.props.onSaveFinished();
            //     }
            //     downloadBlob(this.props.projectFilename, content);
            // }
        });
    }
    render() {
        const { children } = this.props;
        return children(this.props.className, this.downloadProject);
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
};
SB3Downloader.defaultProps = {
    className: "",
};

const mapStateToProps = (state) => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(
        state.scratchGui.vm
    ),
    projectFilename: getProjectFilename(
        state.scratchGui.projectTitle,
        projectTitleInitialState
    ),
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
